import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";
import axios from "axios";
import { LoadingSpinnerHandler } from "@mixins/btnLoading";

let intervalPingPong = null;

export default class extends Controller {
  static targets = [
    "invoiceForm",
    "bankOptions",
    "informTransaction",
    "bankingCountrySelect",

    "stripeForm",
    "montonioPaymentMethods",
    "pingPongIsOrderPaid"
  ];

  static values = {
    montonioWalletUrl: String,
    montonioSuccessUrl: String,
    montonioCancelUrl: String
  };

  connect() {
    const actionName = this.element.getAttribute("data-action");
    if (typeof this[actionName] === "function") this[actionName]();

    const invoiceRequest = document.querySelector("#order_invoice_request");
    if (invoiceRequest?.checked == true) {
      this.showInvoiceForm();
    }

    const termsAgreed = document.querySelector("#order_terms_agreed");
    if (termsAgreed?.checked == true) {
      this.agreeTerms();
    }

    if (this.hasMontonioPaymentMethodsTarget) {
      const paymentMethod =
        document.querySelector(".order_payment_method input:checked")?.value ??
        "montonio";

      switch (paymentMethod) {
        case "montonio":
          this.montonioPaymentMethodsTarget.classList.remove("d-none");
          break;
        case "montonio_stripe":
          this.montonioPaymentMethodsTarget.classList.add("d-none");
          break;
      }
    }
  }

  stripeFormTargetConnected(element) {
    const useSandbox = !!element.querySelector("[name='sandbox']");
    element.querySelector("[name='sandbox']")?.destroy();

    const stripe = {
      stripePublicKey: element.querySelector("[name='stripePublicKey']")?.value,
      stripeClientSecret: element.querySelector("[name='stripeClientSecret']")
        ?.value,
      paymentIntentUuid: element.querySelector("[name='uuid']")?.value,
      onBehalfOf: element.querySelector("[name='onBehalfOf']")?.value,
      amount: element.querySelector("[name='grandTotal']")?.value
    };

    const createOrder = () => {
      return axios.post(this.montonioWalletUrlValue, {
        paymentIntentUuid: stripe.paymentIntentUuid
      });
    };

    const cancelOrder = () => {
      sessionStorage.setItem(
        "alert",
        "Montonio ühendus tekkis tõrke. Palun proovige uuesti või muu makseviisiga"
      );
      axios({
        url: this.montonioCancelUrlValue,
        method: "PATCH",
        headers: {
          Accept: "application/json"
        }
      }).then(({ data }) => {
        if (typeof data === "string") {
          window.location.href = data;
        }
      });
    };

    const handleExpressCheckoutErrorEvent = event => {
      console.log("Express checkout error event", event);
      cancelOrder();
    };
    const handleExpressCheckoutPaymentCompletedEvent = event => {
      console.log("Express checkout payment completed", event);
      window.location.href = this.completedOrderUrlValue;
    };

    // Make sure to replace 'montonio-express-checkout' with the id that you specified as expressCheckoutTargetId in the initializePayment method
    const expressCheckoutElement = document.getElementById(
      "montonio-express-checkout"
    );
    expressCheckoutElement.addEventListener(
      "express-payment-completed",
      handleExpressCheckoutPaymentCompletedEvent
    );
    expressCheckoutElement.addEventListener(
      "express-payment-error",
      handleExpressCheckoutErrorEvent
    );

    const checkoutCallback = async error => {
      if (error) {
        console.log(error);
        cancelOrder();
        throw error;
      }

      await createOrder();
    };

    if (Object.values(stripe).filter(Boolean).length === 5) {
      window.onMontonioLoaded = () => {
        let embeddedPayment =
          Montonio.Checkout.EmbeddedPayments.initializePayment({
            stripePublicKey: stripe.stripePublicKey, // this information is obtained from the POST request on the backend side
            stripeClientSecret: stripe.stripeClientSecret, // this information is obtained from the POST request on the backend side
            paymentIntentUuid: stripe.paymentIntentUuid, // this information is obtained from the POST request on the backend side as the `uuid` key in the response.
            locale: "en", // the language to display the payment input fields for your customer ('de','en','et','fi','lt','lv','pl','ru')
            country: "EE", // country code of your customer (e.g. 'EE', 'PL', 'LV', 'LT'),
            targetId: "montonio-checkout", // the target id where you want to display the payment input fields for your customer,
            expressCheckoutTargetId: "montonio-express-checkout", // the html div id where you want to display the Apple Pay or Google Pay button
            enableExpressCheckout: true,
            onExpressCheckoutAsync: checkoutCallback, // A callback method that is run when the user confirms the payment in the Apple Pay/Google Pay flow. This callback should create the order using the POST /orders endpoint.
            amount: Number(stripe.amount), // the order grand total denoted as cents e.g. 1 EUR should be specified as 100
            currency: "eur", // currency specified as either eur or pln (in lowercase)
            paymentMethod: "card",
            onBehalfOf: stripe.onBehalfOf, // use the value from "onBehalfOf" in the POST /payment-intents/draft response
            version: "v2",
            useSandbox
          });

        let form = document.getElementById("my-checkout");
        form.addEventListener("submit", evt => {
          evt.preventDefault();
          const btn = form.querySelector("button");
          btn.disabled = true;
          LoadingSpinnerHandler.add(btn);

          // 3. Create a new order from your backend and don't forget to attach the paymentUuid.
          // 3.a. you can access the paymentIntentUuid from the SDK method getPaymentIntentUuid() if needed.
          createOrder()
            .then(async ({ data }) => {
              console.log(data);
              if (!data.response) {
                cancelOrder();
                return;
              }
              // 4. Confirm the payment once the order is created using the SDK method confirmPayment()

              embeddedPayment
                .confirmPayment()
                .then(response => {
                  console.log(response);
                  window.location.href = this.montonioSuccessUrlValue;
                })
                .catch(error => {
                  console.error(error);
                  //LoadingSpinnerHandler.remove(btn);
                  //btn.disabled = false;
                  cancelOrder();
                });
            })
            .catch(() => {
              cancelOrder();
            });
        });
      };
    }
  }

  pingPongIsOrderPaidTargetConnected(element) {
    const url = element.dataset["pingPongUrl"];
    const currentUrl = window.location.href;

    // ping pong until server says order is paid
    // assert it only does another query when url remains the same
    // to prevent dumb suprise of forced page reload
    function pingPongUntilPaid() {
      axios({
        url,
        method: "GET",
        maxRedirects: 0,
        headers: {
          Accept: "application/json"
        }
      }).then(({ data }) => {
        if (window.location.href != currentUrl) {
          return;
        }
        if (data === true) window.location.reload();
        else intervalPingPong = setTimeout(pingPongUntilPaid, 5000);
      });
    }

    pingPongUntilPaid();
  }

  pingPongIsOrderPaidTargetDisconnected() {
    clearInterval(intervalPingPong);
  }

  showHideBankPaymentMethods(evt) {
    if (evt.target.value === "montonio") {
      this.montonioPaymentMethodsTarget.classList.remove("d-none");
      return;
    }
    this.montonioPaymentMethodsTarget.classList.add("d-none");
  }

  showInvoiceForm() {
    this.invoiceFormTarget.classList.toggle("d-none");
  }

  agreeTerms() {
    this.bankOptionsTarget.classList.toggle("d-none");
  }

  informTransaction() {
    this.informTransactionTarget.classList.toggle("d-none");
  }

  expandOrder(evt) {
    const target = document.querySelector(evt.target.dataset.rtsTarget);
    evt.target.querySelector("i").classList.toggle("counter-rotate");
    target.classList.toggle("d-none");

    const row = evt.target.closest("tr");
    row.classList.toggle("table-active");
  }

  checkAllOrderUpdate(evt) {
    $("input.order-select:checkbox").prop(
      "checked",
      $(evt.target).prop("checked")
    );
  }

  openOrderItemEdit(evt) {
    evt.preventDefault();
    const href = evt.currentTarget.href;
    axios({
      url: href,
      method: "POST",
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    }).then(({ data: response }) => {
      Turbo.session.receivedMessageFromStream(response);

      window.requestAnimationFrame(() => {
        window.initSelect2();
        window.initDatepicker();
      });
    });
  }

  openConfirmationDialog(evt) {
    evt.preventDefault();
    const href = evt.currentTarget.href;
    axios({
      url: href,
      method: "POST",
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    }).then(({ data: response }) => {
      Turbo.session.receivedMessageFromStream(response);
    });
  }

  confirmPaymentSubmission(event) {
    // Stimulus doesn't support custom events, so have to do this way
    event.target.addEventListener(
      "form:submit-end",
      ({ detail }) => {
        if (detail.formSubmission.result.success === true) {
          document.querySelector("#modal").addEventListener(
            "hidden.bs.modal",
            () => {
              detail.formSubmission.formElement.remove();
            },
            { once: true }
          );
          let modal = new Modal($("#modal")[0]);
          modal.hide();
        }
      },
      { once: true }
    );
  }

  bankingCountrySelectTargetConnected(el) {
    const value = el.value;

    document.querySelectorAll(".country-bankings").forEach(el => {
      el.classList.add("d-none");
    });

    document
      .querySelector(`#countryBanking-${value}`)
      .classList.remove("d-none");
  }

  updateBankSelections(evt) {
    const value = evt.target.value;

    document.querySelectorAll(".country-bankings").forEach(el => {
      el.classList.add("d-none");
    });

    document
      .querySelector(`#countryBanking-${value}`)
      .classList.remove("d-none");
  }

  toggleInvoicePayment(evt) {
    evt.target.disabled = true;
    axios({
      url: evt.params.url,
      method: "PATCH",
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      },
      data: {
        value: evt.target.checked ? "1" : "0"
      }
    }).then(({ data: response }) => {
      evt.target.disabled = false;
      Turbo.session.receivedMessageFromStream(response);
    });
  }
}
